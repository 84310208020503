import React from 'react';
import {Container, Typography, Grid, Paper, Button, Avatar, CardContent, Card, useTheme} from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from "react-router-dom";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CloudIcon from '@mui/icons-material/Cloud';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ScaleIcon from '@mui/icons-material/Balance';
const Header = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    };

    return (
        <>
            <AppBar sx={{backgroundColor: '#f5f4f2'}}>
                <Container>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{mr: 2}}
                            >
                                <Avatar alt="Icon" src={'etsy profile.png'}/>
                            </IconButton>
                            <Typography
                                variant="h6"
                                noWrap
                                component="span"
                                color='textPrimary'
                                sx={{display: {xs: 'none', sm: 'block'}}}
                            >
                                About <Typography variant="h6" noWrap component="span"
                                                  sx={{color: theme.palette.secondary.main, fontWeight: 'bold'}}>Global
                                Arts
                                Online</Typography>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="span"
                                color='textPrimary'
                                sx={{display: {xs: 'none', sm: 'block'}}}
                            >
                                <IconButton onClick={handleBack} aria-label="Go Back">
                                    <ArrowBackIosIcon/>
                                </IconButton>
                                Back to Home
                            </Typography>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar/>
        </>
    )
}

const values = [
    {
        title: 'Originality',
        description: 'Creativity and authenticity underpin everything we do - it’s hard-coded into our DNA.',
        icon: <FingerprintIcon/>,
    },
    {
        title: 'Openness',
        description: 'We strive to ensure art is accessible for everyone, whether you’re an artist or customer.',
        icon: <CloudIcon/>,
    },
    {
        title: 'Courage',
        description: 'We’re unafraid to make bold choices if it means delivering a better experience.',
        icon: <FavoriteIcon/>,
    },
    {
        title: 'Decency',
        description: 'We champion a fair, equitable and open marketplace that rewards both artists and customers.',
        icon: <ScaleIcon/>,
    },
];

const MissionStatement = () => {

    const theme = useTheme();

    return (
        <Box sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: theme.palette.primary.light,

            color: 'white',
            minHeight: '95vh',
        }}>
            <Box sx={{
                width: '100%',
                minHeight: '500px',
                backgroundImage: 'url("paint.png")', // Use backgroundImage to set the background image
                backgroundSize: 'cover', // Optional: Set the background size to cover the box
                backgroundPosition: 'center', // Optional: Center the background image
            }}>

            </Box>
            <Box
                sx={{
                    pl: 25,
                    pr: 25,
                    pt: 15,
                    pb: 15,
                }}
            >
                <Typography variant="h4" gutterBottom align="center" sx={{fontWeight: 'bold'}}>
                    Our mission for a better art world
                </Typography>
                <Typography variant="subtitle1" gutterBottom align="center" p={5} sx={{width: '70%'}} mr='auto'
                            ml='auto'>
                    At Global Arts Online, we do things a little differently. Our mission is to make art accessible,
                    affordable, and
                    a
                    viable career for artists. We help buyers find pieces that they can’t find anywhere else. We value
                    doing
                    the
                    right thing for our artists and customers and staying true to who we are above all else.
                </Typography>
                <Grid container spacing={5} justifyContent="center" alignItems="center">
                    {values.map((value, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card sx={{
                                textAlign: 'center', minHeight: 200,
                                backgroundColor: (index < 2) ? theme.palette.primary.light : theme.palette.primary.main
                            }}>
                                <CardContent>
                                    <Box sx={{fontSize: 30, color: 'white'}}>{value.icon}</Box>
                                    <Typography variant="h5" component="div" sx={{color: 'white'}}>
                                        {value.title}
                                    </Typography>
                                    <Typography variant="body2" color='white' mt={5}>
                                        {value.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

const About: React.FC = () => {


    return (
        <Container>
            <Header/>
            <MissionStatement/>
        </Container>
    );
};

export default About;
