import * as React from 'react';
import Header from '../component/Header';
import MiddleBanner from '../component/MiddleBanner';
import {Box, Container, Grid, Link, Paper, Typography, useTheme} from '@mui/material';
import styles from './home.module.css';

type Category = {
    shopBy: string;
    categories: string[];
}

const categories: Category[] = [
    {
        shopBy: 'Shop by style',
        categories: [
            'Abstract',
            'Impressionistic',
            'Naive',
            'Pop art',
            'Surrealistic',
        ],
    },
    {
        shopBy: 'Shop by subject',
        categories: [
            'Animals',
            'Portraits',
            'Florals',
            'Landscapes',
            'Still life',
        ],
    },
    {
        shopBy: 'Shop by price',
        categories: [
            '$0-$250',
            '$250-$500',
            '$500-$1000',
            '$1000+',
        ],
    },
];

const ShopBy = () => {
    return (
        <Paper elevation={3} sx={{p: 4, borderRadius: '8px'}}>
            {categories.map((category) => (
                <Box key={category.shopBy} mb={4}>
                    <Typography variant="h6" gutterBottom mb={2} sx={{fontWeight: 'bold', color: '#333'}}>
                        {category.shopBy}
                    </Typography>
                    {category.categories.map((c) => (
                        <Typography variant="subtitle1" component="p" gutterBottom key={c} sx={{color: '#555'}}>
                            {c}
                        </Typography>
                    ))}
                </Box>
            ))}
        </Paper>
    );
};
const DigitalArtSalesBanner = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 6,
                bgcolor: theme.palette.primary.dark,
                color: theme.palette.secondary.contrastText,
            }}
        >
            <Typography variant="h6" sx={{fontWeight: 'bold'}}>Digital Art Sales</Typography>
            <Typography variant="h6" sx={{textDecoration: 'underline'}}>Save up to 75%</Typography>
        </Box>
    );
};

type AspectRatio = "twoByThree" | "sixteenByNine";

const Item = ({children, url, width = '100%', height = '350px'}: {
    children: React.ReactNode,
    url: string,
    ar?: AspectRatio,
    width?: string,
    height?: string,
}) => {

    return (
        <Paper elevation={3} sx={{p: 2}}>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <img src={url} alt="img"
                     width={width} height={height}/>
                <Typography variant="h6" component="div" mt={2} sx={{fontWeight: 'bold'}}>
                    {children}
                </Typography>
            </Box>
        </Paper>
    );
};

const Gallery = () => {
    return (
        <Grid container spacing={6}>
            <Grid item md={4}>
                <Item url="abstract.png" width='269px' height='136px'>Abstract</Item>
            </Grid>
            <Grid item md={4}>
                <Item url="pencil.png" width='269px' height='136px'>Pencil art</Item>
            </Grid>
            <Grid item md={4}>
                <Item url="sofa.png" width='269px' height='136px'>Modern</Item>
            </Grid>
            <Grid item md={8}>
                <Item url="paris.png" width='560px' height='280px'>Landscape</Item>
            </Grid>
            <Grid item md={4}>
                <Item url="abstract-tiles.png" width='270px' height='280px'>Pattern</Item>
            </Grid>
            <Grid md={12} item>
                <DigitalArtSalesBanner/>
            </Grid>
            <Grid item md={4}>
                <Item url="stickers.png">Stickers</Item>
            </Grid>
            <Grid item md={8}>
                <Item url="girl.png" ar='sixteenByNine'>Fantasy</Item>
            </Grid>
            <Grid item md={4}>
                <Item url="cityportraits.png" width='269px' height='136px'>City Portraits</Item>
            </Grid>
            <Grid item md={4}>
                <Item url="mockup.png" width='269px' height='136px'>Mockups</Item>
            </Grid>
            <Grid item md={4}>
                <Item url="abstract-landscape.png" width='269px' height='136px'>Abstract</Item>
            </Grid>
        </Grid>
    );
};

type FooterLink = {
    name: string;
    url: string;
}

const footerLinks: FooterLink[] = [
    {name: 'About', url: '/about'},
    {name: 'Help', url: '/#'},
    {name: 'Career', url: '/#'},
    {name: 'Legal', url: '/#'},
];

const Footer = () => {
    const theme = useTheme();

    return (
        <Container sx={{
            display: 'flex', backgroundColor: theme.palette.primary.main, color: 'white',
            flexDirection: 'column', justifyContent: 'space-between', mt: 5
        }}>
            <Box flexDirection="row" display="flex" justifyContent="space-between" p={6}>
                {footerLinks.map(({url, name}) => (
                    <Typography variant="h6" gutterBottom key={name} sx={{fontWeight: 'bold'}}>
                        <Link href={url} color='inherit' underline='none'>{name}</Link>
                    </Typography>
                ))}
            </Box>
            <Box display="flex" justifyContent="center" mb={7} gap={3}>
                <Link href="/terms-of-use" color="inherit" underline="none">
                    &copy; 2023 Global Arts Online |
                </Link>
                <Link href="/terms-of-use" color="inherit" underline="none">
                    Terms of Use |
                </Link>
                <Link href="/privacy-policy" color="inherit" underline="none">
                    Privacy Policy
                </Link>
            </Box>
        </Container>
    );
};

const ArtCategories = ['Digital Pop arts', 'Digital Abstract art', 'Abstract Arts', 'Mockup Templates'];

export default function Home() {
    return (
        <>
            <Header/>
            <MiddleBanner/>
            <Container className={styles.container}>
                <Grid container spacing={5}>
                    {ArtCategories.map((category) => (
                            <Grid md={3} item key={category}>
                                <Paper elevation={3} sx={{p: 4, borderRadius: '8px', textAlign:'center'}}>
                                    <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                        {category}
                                    </Typography>
                                </Paper>
                            </Grid>
                        )
                    )}
                    <Grid md={3} item>
                        <ShopBy/>
                    </Grid>
                    <Grid md={9} item>
                        <Gallery/>
                    </Grid>
                </Grid>
            </Container>
            <Footer/>
        </>
    );
}