import * as React from "react";
import Home from "./page/Home";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import Error from './page/Error';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme} from "@mui/material";
import About from "./page/About";
import Join from "./page/Join";
import Login from "./page/Login";
import ForgotPassword from "./page/ForgotPassword";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <Error/>,
    },
    {
        path: "/about",
        element: <About/>,
        errorElement: <Error/>,
    },
    {
        path: "/join",
        element: <Join/>,
        errorElement: <Error/>,
    },
    {
        path: "/login",
        element: <Login/>,
        errorElement: <Error/>,
    },
    {
        path: "/forgot",
        element: <ForgotPassword/>,
        errorElement: <Error/>,
    },
]);

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: '"sul-sans",helvetica,sans-serif',
        fontSize: 12,
    },
    spacing: 4,
    shape: {
        borderRadius: 8,
    }
});


ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();