import * as React from 'react';
import {Box, Container, Typography, useTheme} from '@mui/material';

const MiddleBanner = () => {
    const theme = useTheme();
    return (

        <Container maxWidth={false} disableGutters
                   sx={{
                       width: '100%',
                       bgcolor: theme.palette.primary.light,
                       color: 'white',
                       p: 5,
                   }}>
            <Box ml={5} mr={5}>
                <Typography variant="h3" component="h1" sx={{fontWeight: 'bold', textAlign: 'center'}}>
                    Digital art for sale
                </Typography>
                <Box ml="auto" mr="auto" maxWidth='50%'>
                    <Typography
                        variant="subtitle1"
                        component="p"
                        sx={{textAlign: 'center', mt: 2}}
                    >
                        Embrace the Digital Realm: Explore the innovative world of digital artists who harness technology to craft their masterpieces. Support independent artists worldwide by purchasing their digital artworks.
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default MiddleBanner;
